import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default [
  {
    name: "O nas",
    slug: "/about",
    image: (
      <StaticImage
        className="features__item--img"
        src="../../images/home/onas.jpg"
      />
    ),
    excerpt:
      "Posiadamy ponad 10 letnie doświadczenie w prowadzeniu i oprawie muzycznej imprez, uroczystości weselnych, bankietów, eventów itd., gdzie oprawa muzyczna stanowi niemal 100% naszej pracy.",
    linkTitle: "Dowiedz się o nas więcej",
  },
  {
    name: "Członkowie zespołu",
    slug: "/band",
    image: (
      <StaticImage
        className="features__item--img"
        src="../../images/home/band.jpg"
      />
    ),
    excerpt:
      "Nasz zespół powstał przede wszystkim z ogromnej pasji do muzyki, a z czasem pojawiła się chęć stworzenia Grupy, która z zaangażowaniem i profesjonalizmem wykonuje swoją pracę.",
    linkTitle: "Poznaj nas",
  },
  {
    name: "Kompleksowa organizacja",
    slug: "/organisation",
    image: (
      <StaticImage
        className="features__item--img"
        src="../../images/home/oferta.jpg"
      />
    ),
    excerpt:
      "Nasza bogata oferta usług stale się rozwija, dzięki czemu możemy sprostać nawet największym wymaganiom! To Wy dopasowujecie atrakcje do waszej imprezy i budżetu, a każdy taki dodatek to miłe wspomnienia dla wszystkich uczestników przyjęcia.",
    linkTitle: "Zobacz naszą ofertę",
  },
]
