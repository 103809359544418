import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import Perk from "./Perk"
import { FaBlackTie, FaGlassCheers, FaGuitar, FaUserTie } from "react-icons/fa"

const PerksModule = () => {
  return (
    <PerksModuleStyles>
      <StaticImage
        className="perks__image--bg"
        src="../../images/home/perks.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div>
      <div className="container">
        <Perk
          title="Jakość Muzyki"
          content="W pełni profesjonalny sprzęt grający i nagłośnieniowy"
        >
          <FaGuitar height={70}/>
        </Perk>
        <Perk
          title="Styl z klimatem"
          content="Dobra zabawa z odrobiną szaleństwa"
        >
          <FaBlackTie />
        </Perk>
        <Perk
          title="Nowoczesna wizualizacja"
          content="Wielopunktowe i klimatyczne oświetlenie na najwyższym poziomie"
        >
          <FaGlassCheers />
        </Perk>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
