import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"

const BasicTextModule = () => {
  return (
    <BasicTextModuleStyles>
      <div className="container">
        <h2>Jedyni w swoim rodzaju<br></br>Nas zapamiętasz na długie lata!</h2>
        <p style={{ marginBottom: "60px" }}>
          Wesele, bankiet, impreza firmowa czy może ważny event… ? Niezależnie
          od tego na jakiego typu imprezę poszukujesz zespołu trafiłeś we
          właściwe miejsce. Memento Group to przede wszystkim ludzie do zadań
          specjalnych! Jesteśmy dla Ciebie, dla Was, po to aby zapewnić
          kompleksową organizację eventów, bankietów i tych najważniejszych…
          wesel, które na długo zapadną w pamięci naszym odbiorcom.
        </p>
        <h2>To my tworzymy Wasze wspomnienia</h2>
        <p style={{ marginBottom: "60px" }}>
          Wydarzenia obsługiwane przez Memento Group to nie tylko dobra muzyka,
          ale przede wszystkim gwarancja zabawy na najwyższym poziomie. Bogaty
          repertuar, obejmujący pełen wachlarz gatunków muzycznych, zadowoli gusta
          nawet najbardziej wymagających osób. Imprezy przez nas obsługiwane
          dostarczają świetnej i niezapomnianej zabawy, co kreuje wspomnienia na
          długie lata.
        </p>
        <h2>Twoja wydarzenie to nasza praca</h2>
        <p style={{ marginBottom: "60px" }}>
          Każda impreza, bankiet, event czy wesele to dla nas nowe wyzwanie, a
          do każdego klienta podchodzimy indywidualnie. To sprawia, że każda
          uroczystość ma swój niepowtarzalny charakter. Nasza propozycja
          skierowana jest dla wszystkich tych, którzy cenią profesjonalizm oraz
          doskonałą zabawę.
        </p>
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
