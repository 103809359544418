import styled from "styled-components"

export const BannerModuleStyles = styled.section`
  height: 50vh;
  position: relative;
  padding: 30px var(--borderSpacing);
  background: rgba(0, 0, 0, 0.55);

  .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .banner__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    ${({ right }) =>
      right
        ? `@media (max-width: 768px) {
      // width: 250%;
    }`
        : ""}

        &.mobile {
          display: block;
        }
    
        &.desktop {
          display: none;
        }
    
        @media (min-width: 768px) {
          &.mobile {
            display: none;
          }
    
          &.desktop {
            display: block;
          }
        }
  }

  .banner__content {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    min-height: 33vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    ${({ right }) =>
      right ? ` align-items: flex-end;` : ` align-items: flex-start;`}
    ${({ right }) =>
      right
        ? `text-align: right;`
        : `text-align: left;`}

    @media (min-width: 768px) {
      max-width: 80%;
    }

    h1,
    .price {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: var(--bannerTitle);
      letter-spacing: -1px;
      margin-bottom: 30px;
    }

    h2 {
      margin-top: 0;
      font-weight: 300;
      font-size: var(--bannerSubTitle);
      letter-spacing: -0.5px;
    }

    button {
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 22px;
      display: flex;
    }
  }
`
