import * as React from "react"
import { ProductsStyles } from "../Products/ProductsStyles"
import Product from "../Products/Product"
import data from "./data"

// 3 kolumny na glownej stronie
const Features = () => {
  return (
    <ProductsStyles>
      <div className="features__container">
        <div className="features__container--scroll">
          {data.map(d => {
            return <Product {...d} />
          })}
        </div>
      </div>
    </ProductsStyles>
  )
}

export default Features
