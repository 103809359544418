import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Features from "../components/Features/Features"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: center;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }
`

const Index = () => {
  return (
    <>
      <Seo title="Strona Główna" />
      <Layout>
        <BannerModule
          title="Zespół"
          subTitle="Muzyką żyjemy na codzień, a na scenie dajemy z siebie 101% !"
          link="/offer"
          linkText="Poznaj naszą ofertę"
        >
          <StaticImage
            className="banner__image desktop"
            imgClassName="banner__image--content"
            src="../images/home/zespol.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
          <StaticImage
            className="banner__image mobile"
            imgClassName="banner__image--content"
            src="../images/home/zespol-mobile.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
        </BannerModule>
        <BannerModule
          title="Śpiewający DJ & Live ACT"
          subTitle={
            <React.Fragment>
              Nie wiesz czy na swoje przyjęcie wybrać DJ czy zespół muzyczny ?! 
              <br />
          Ta oferta jest uszyta na miarę specjalnie dla Ciebie !
            </React.Fragment>
          }
          right={true}
          link="/dj"
          linkText="Zobacz co oferujemy"
        >
          <StaticImage
            className="banner__image desktop"
            imgClassName="banner__image--content"
            src="../images/dj/dj1homebig.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
          <StaticImage
            className="banner__image mobile"
            imgClassName="banner__image--content"
            src="../images/dj/dj1homesmall.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
        </BannerModule>
        <BannerModule
          imgUrl="../images/home/organizacja.jpg"
          title="Kompleksowa Organizacja"
          subTitle="Twoje wydarzenie to nasza praca"
          link="/organisation"
          linkText="Zobacz co oferujemy"
        >
          <StaticImage
            className="banner__image"
            imgClassName="banner__image--content"
            src="../images/home/organizacja.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
        </BannerModule>
        <BasicTextModule />
        <PerksModule></PerksModule>

        <Container>
          <h2>Nasza praca to przyjaźń i pasja</h2>
          <p>
            Wiele kilometrów przejechanych tras, wiele godzin spędzonych razem
            na próbach i samych imprezach, zbudowało w Memento Group długoletnią
            przyjaźń. To doświadczenie oraz wspólna pasja i miłość do tworzenia
            niesamowitych wspomnień powodują, że nie przepracowaliśmy ani
            jednego dnia. To przekłada się na niesamowitą energię, brzmienie i atmosferę na każdej imprezie.
            Muzką żyjemy na codzień, a na scenie dajemy z siebie 101% !!!
          </p>
        </Container>

        <Features />
      </Layout>
    </>
  )
}

export default Index
